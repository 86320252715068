/* eslint-disable */
import * as Types from './types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from './hooks';
const defaultOptions = {} as const;
export const UserShortFragmentDoc = gql`
    fragment UserShort on User {
  id
  name
  avatar {
    thumbnailUrl
    alt
  }
  company
  status
  url
}
    `;
export const CommentFragmentDoc = gql`
    fragment Comment on Comment {
  id
  level
  text
  publishedAt
  publishedBy {
    ...UserShort
  }
  likesCount
  isLiked
}
    ${UserShortFragmentDoc}`;
export const CommentErrorFragmentDoc = gql`
    fragment CommentError on CommentError {
  code
  field
  message
}
    `;
export const MainArticleFragmentDoc = gql`
    fragment MainArticle on Article {
  id
  title
  tizer
  image {
    previewUrl
    alt
  }
  rubrica {
    groupId
    groupName
  }
  url
}
    `;
export const MainNewsFragmentDoc = gql`
    fragment MainNews on News {
  id
  publishedAt
  title
  tizer
  image {
    previewUrl
    alt
  }
  rubrica {
    short
  }
  url
}
    `;
export const CompanyWithContactsFragmentDoc = gql`
    fragment CompanyWithContacts on Company {
  id
  short
  title
  logo {
    thumbnailUrl
    alt
  }
  inn
  ogrn
  address
  phone
  email
  status
  url
}
    `;
export const CompanyAdminBaseFragmentDoc = gql`
    fragment CompanyAdminBase on CompanyAdmin {
  id
  title
  short
  status
  isActive
  feedbacksUnread
}
    `;
export const AddressUpdateErrorFragmentDoc = gql`
    fragment AddressUpdateError on AddressUpdateError {
  code
  field
  message
}
    `;
export const EmailConfirmRequestErrorFragmentDoc = gql`
    fragment EmailConfirmRequestError on EmailConfirmRequestError {
  code
  field
  message
}
    `;
export const SupportRequestErrorFragmentDoc = gql`
    fragment SupportRequestError on SupportRequestError {
  code
  field
  message
}
    `;
export const MagazineSubscriptionErrorFragmentDoc = gql`
    fragment MagazineSubscriptionError on MagazineSubscriptionError {
  code
  field
  message
}
    `;
export const MarketOfferClickFragmentDoc = gql`
    fragment MarketOfferClick on OfferClick {
  id
  clickDate
  clickPrice
  offer {
    id
    title
    url
    isActive
  }
  offerPrice
  url
  referer
  userAgent
  ymUid
}
    `;
export const MarketTermsFragmentDoc = gql`
    fragment MarketTerms on Terms {
  payment
  paymentChoices {
    code
    description
  }
  paymentMemo
  delivery
  deliveryChoices {
    code
    description
  }
  deliveryMemo
  client
  clientChoices {
    code
    description
  }
}
    `;
export const TermsErrorFragmentDoc = gql`
    fragment TermsError on TermsError {
  code
  field
  message
}
    `;
export const MessageCountFragmentDoc = gql`
    fragment MessageCount on MessageCount {
  inbox
  inboxUnread
  sentbox
}
    `;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
  hasPreviousPage
  hasNextPage
  startCursor
  endCursor
}
    `;
export const CompanyShortFragmentDoc = gql`
    fragment CompanyShort on Company {
  id
  short
  logo {
    thumbnailUrl
    alt
  }
  status
  url
}
    `;
export const TimelineFragmentDoc = gql`
    fragment Timeline on Timeline {
  id
  pubDate
  contentType {
    name
    naturalKey
  }
  company {
    ...CompanyShort
  }
  user {
    ...UserShort
  }
  title
  tizer
  image {
    previewUrl
    alt
  }
  video
  url
  analytics {
    totalHits
  }
}
    ${CompanyShortFragmentDoc}
${UserShortFragmentDoc}`;
export const UserProfileFragmentDoc = gql`
    fragment UserProfile on Profile {
  name
  email
  phone
  isAddressSet
  isEmailActive
  isNeedUpdate
}
    `;
export const MagazineSubscribeDocument = gql`
    mutation magazineSubscribe($email: String!, $name: String!) {
  subscriptionCreate(input: {email: $email, name: $name, scopes: MAGAZINE}) {
    subscription {
      id
      isSubscribed
    }
    errors {
      code
      field
      message
    }
  }
}
    `;
export type MagazineSubscribeMutationFn = Apollo.MutationFunction<Types.MagazineSubscribeMutation, Types.MagazineSubscribeMutationVariables>;

/**
 * __useMagazineSubscribeMutation__
 *
 * To run a mutation, you first call `useMagazineSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMagazineSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [magazineSubscribeMutation, { data, loading, error }] = useMagazineSubscribeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useMagazineSubscribeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.MagazineSubscribeMutation, Types.MagazineSubscribeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.MagazineSubscribeMutation, Types.MagazineSubscribeMutationVariables>(MagazineSubscribeDocument, options);
      }
export type MagazineSubscribeMutationHookResult = ReturnType<typeof useMagazineSubscribeMutation>;
export type MagazineSubscribeMutationResult = Apollo.MutationResult<Types.MagazineSubscribeMutation>;
export type MagazineSubscribeMutationOptions = Apollo.BaseMutationOptions<Types.MagazineSubscribeMutation, Types.MagazineSubscribeMutationVariables>;
export const CommentCreateDocument = gql`
    mutation CommentCreate($id: ID!, $input: CommentCreateInput!) {
  commentCreate(id: $id, input: $input) {
    comment {
      ...Comment
    }
    errors {
      ...CommentError
    }
  }
}
    ${CommentFragmentDoc}
${CommentErrorFragmentDoc}`;
export type CommentCreateMutationFn = Apollo.MutationFunction<Types.CommentCreateMutation, Types.CommentCreateMutationVariables>;

/**
 * __useCommentCreateMutation__
 *
 * To run a mutation, you first call `useCommentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commentCreateMutation, { data, loading, error }] = useCommentCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommentCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CommentCreateMutation, Types.CommentCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CommentCreateMutation, Types.CommentCreateMutationVariables>(CommentCreateDocument, options);
      }
export type CommentCreateMutationHookResult = ReturnType<typeof useCommentCreateMutation>;
export type CommentCreateMutationResult = Apollo.MutationResult<Types.CommentCreateMutation>;
export type CommentCreateMutationOptions = Apollo.BaseMutationOptions<Types.CommentCreateMutation, Types.CommentCreateMutationVariables>;
export const CommentDeleteDocument = gql`
    mutation CommentDelete($id: ID!) {
  commentDelete(id: $id) {
    errors {
      ...CommentError
    }
  }
}
    ${CommentErrorFragmentDoc}`;
export type CommentDeleteMutationFn = Apollo.MutationFunction<Types.CommentDeleteMutation, Types.CommentDeleteMutationVariables>;

/**
 * __useCommentDeleteMutation__
 *
 * To run a mutation, you first call `useCommentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commentDeleteMutation, { data, loading, error }] = useCommentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommentDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CommentDeleteMutation, Types.CommentDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CommentDeleteMutation, Types.CommentDeleteMutationVariables>(CommentDeleteDocument, options);
      }
export type CommentDeleteMutationHookResult = ReturnType<typeof useCommentDeleteMutation>;
export type CommentDeleteMutationResult = Apollo.MutationResult<Types.CommentDeleteMutation>;
export type CommentDeleteMutationOptions = Apollo.BaseMutationOptions<Types.CommentDeleteMutation, Types.CommentDeleteMutationVariables>;
export const CommentLikeDocument = gql`
    mutation CommentLike($id: ID!) {
  commentLike(id: $id) {
    errors {
      ...CommentError
    }
  }
}
    ${CommentErrorFragmentDoc}`;
export type CommentLikeMutationFn = Apollo.MutationFunction<Types.CommentLikeMutation, Types.CommentLikeMutationVariables>;

/**
 * __useCommentLikeMutation__
 *
 * To run a mutation, you first call `useCommentLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommentLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commentLikeMutation, { data, loading, error }] = useCommentLikeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommentLikeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CommentLikeMutation, Types.CommentLikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CommentLikeMutation, Types.CommentLikeMutationVariables>(CommentLikeDocument, options);
      }
export type CommentLikeMutationHookResult = ReturnType<typeof useCommentLikeMutation>;
export type CommentLikeMutationResult = Apollo.MutationResult<Types.CommentLikeMutation>;
export type CommentLikeMutationOptions = Apollo.BaseMutationOptions<Types.CommentLikeMutation, Types.CommentLikeMutationVariables>;
export const CommentLikeDeleteDocument = gql`
    mutation CommentLikeDelete($id: ID!) {
  commentLikeDelete(id: $id) {
    errors {
      ...CommentError
    }
  }
}
    ${CommentErrorFragmentDoc}`;
export type CommentLikeDeleteMutationFn = Apollo.MutationFunction<Types.CommentLikeDeleteMutation, Types.CommentLikeDeleteMutationVariables>;

/**
 * __useCommentLikeDeleteMutation__
 *
 * To run a mutation, you first call `useCommentLikeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommentLikeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commentLikeDeleteMutation, { data, loading, error }] = useCommentLikeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommentLikeDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CommentLikeDeleteMutation, Types.CommentLikeDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CommentLikeDeleteMutation, Types.CommentLikeDeleteMutationVariables>(CommentLikeDeleteDocument, options);
      }
export type CommentLikeDeleteMutationHookResult = ReturnType<typeof useCommentLikeDeleteMutation>;
export type CommentLikeDeleteMutationResult = Apollo.MutationResult<Types.CommentLikeDeleteMutation>;
export type CommentLikeDeleteMutationOptions = Apollo.BaseMutationOptions<Types.CommentLikeDeleteMutation, Types.CommentLikeDeleteMutationVariables>;
export const GetCommentsDocument = gql`
    query GetComments($id: ID!, $first: Int = 100) {
  me {
    id
    name
    avatar {
      previewUrl
      alt
    }
  }
  comments(id: $id, first: $first) {
    totalCount
    edges {
      node {
        ...Comment
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${CommentFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useGetCommentsQuery__
 *
 * To run a query within a React component, call `useGetCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetCommentsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.GetCommentsQuery, Types.GetCommentsQueryVariables> & ({ variables: Types.GetCommentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetCommentsQuery, Types.GetCommentsQueryVariables>(GetCommentsDocument, options);
      }
export function useGetCommentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetCommentsQuery, Types.GetCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetCommentsQuery, Types.GetCommentsQueryVariables>(GetCommentsDocument, options);
        }
export function useGetCommentsSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<Types.GetCommentsQuery, Types.GetCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<Types.GetCommentsQuery, Types.GetCommentsQueryVariables>(GetCommentsDocument, options);
        }
export type GetCommentsQueryHookResult = ReturnType<typeof useGetCommentsQuery>;
export type GetCommentsLazyQueryHookResult = ReturnType<typeof useGetCommentsLazyQuery>;
export type GetCommentsSuspenseQueryHookResult = ReturnType<typeof useGetCommentsSuspenseQuery>;
export type GetCommentsQueryResult = Apollo.QueryResult<Types.GetCommentsQuery, Types.GetCommentsQueryVariables>;
export const MagazineSubscriptionCreateDocument = gql`
    mutation MagazineSubscriptionCreate($input: MagazineSubscriptionCreateInput!) {
  magazineSubscriptionCreate(input: $input) {
    errors {
      ...MagazineSubscriptionError
    }
  }
}
    ${MagazineSubscriptionErrorFragmentDoc}`;
export type MagazineSubscriptionCreateMutationFn = Apollo.MutationFunction<Types.MagazineSubscriptionCreateMutation, Types.MagazineSubscriptionCreateMutationVariables>;

/**
 * __useMagazineSubscriptionCreateMutation__
 *
 * To run a mutation, you first call `useMagazineSubscriptionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMagazineSubscriptionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [magazineSubscriptionCreateMutation, { data, loading, error }] = useMagazineSubscriptionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMagazineSubscriptionCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.MagazineSubscriptionCreateMutation, Types.MagazineSubscriptionCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.MagazineSubscriptionCreateMutation, Types.MagazineSubscriptionCreateMutationVariables>(MagazineSubscriptionCreateDocument, options);
      }
export type MagazineSubscriptionCreateMutationHookResult = ReturnType<typeof useMagazineSubscriptionCreateMutation>;
export type MagazineSubscriptionCreateMutationResult = Apollo.MutationResult<Types.MagazineSubscriptionCreateMutation>;
export type MagazineSubscriptionCreateMutationOptions = Apollo.BaseMutationOptions<Types.MagazineSubscriptionCreateMutation, Types.MagazineSubscriptionCreateMutationVariables>;
export const MainArticlesMoreDocument = gql`
    query MainArticlesMore($after: String, $first: Int) {
  articles(after: $after, first: $first, filter: {publishMagazine: false}) {
    edges {
      node {
        ...MainArticle
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${MainArticleFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useMainArticlesMoreQuery__
 *
 * To run a query within a React component, call `useMainArticlesMoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainArticlesMoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainArticlesMoreQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useMainArticlesMoreQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.MainArticlesMoreQuery, Types.MainArticlesMoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MainArticlesMoreQuery, Types.MainArticlesMoreQueryVariables>(MainArticlesMoreDocument, options);
      }
export function useMainArticlesMoreLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MainArticlesMoreQuery, Types.MainArticlesMoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MainArticlesMoreQuery, Types.MainArticlesMoreQueryVariables>(MainArticlesMoreDocument, options);
        }
export function useMainArticlesMoreSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<Types.MainArticlesMoreQuery, Types.MainArticlesMoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<Types.MainArticlesMoreQuery, Types.MainArticlesMoreQueryVariables>(MainArticlesMoreDocument, options);
        }
export type MainArticlesMoreQueryHookResult = ReturnType<typeof useMainArticlesMoreQuery>;
export type MainArticlesMoreLazyQueryHookResult = ReturnType<typeof useMainArticlesMoreLazyQuery>;
export type MainArticlesMoreSuspenseQueryHookResult = ReturnType<typeof useMainArticlesMoreSuspenseQuery>;
export type MainArticlesMoreQueryResult = Apollo.QueryResult<Types.MainArticlesMoreQuery, Types.MainArticlesMoreQueryVariables>;
export const MainNewsMoreDocument = gql`
    query MainNewsMore($after: String, $first: Int) {
  news(after: $after, first: $first) {
    edges {
      node {
        ...MainNews
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${MainNewsFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useMainNewsMoreQuery__
 *
 * To run a query within a React component, call `useMainNewsMoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainNewsMoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainNewsMoreQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useMainNewsMoreQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.MainNewsMoreQuery, Types.MainNewsMoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MainNewsMoreQuery, Types.MainNewsMoreQueryVariables>(MainNewsMoreDocument, options);
      }
export function useMainNewsMoreLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MainNewsMoreQuery, Types.MainNewsMoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MainNewsMoreQuery, Types.MainNewsMoreQueryVariables>(MainNewsMoreDocument, options);
        }
export function useMainNewsMoreSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<Types.MainNewsMoreQuery, Types.MainNewsMoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<Types.MainNewsMoreQuery, Types.MainNewsMoreQueryVariables>(MainNewsMoreDocument, options);
        }
export type MainNewsMoreQueryHookResult = ReturnType<typeof useMainNewsMoreQuery>;
export type MainNewsMoreLazyQueryHookResult = ReturnType<typeof useMainNewsMoreLazyQuery>;
export type MainNewsMoreSuspenseQueryHookResult = ReturnType<typeof useMainNewsMoreSuspenseQuery>;
export type MainNewsMoreQueryResult = Apollo.QueryResult<Types.MainNewsMoreQuery, Types.MainNewsMoreQueryVariables>;
export const SmartlightExpertAdviceDocument = gql`
    mutation smartlightExpertAdvice($email: String!, $name: String!, $question: String!, $company: SmartlightCompanyEnum!) {
  smartlightExpertAdviceCreate(
    input: {email: $email, name: $name, question: $question, company: $company}
  ) {
    expertAdvice {
      id
      created
      email
      name
      question
    }
    errors {
      code
      field
      message
    }
  }
}
    `;
export type SmartlightExpertAdviceMutationFn = Apollo.MutationFunction<Types.SmartlightExpertAdviceMutation, Types.SmartlightExpertAdviceMutationVariables>;

/**
 * __useSmartlightExpertAdviceMutation__
 *
 * To run a mutation, you first call `useSmartlightExpertAdviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSmartlightExpertAdviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [smartlightExpertAdviceMutation, { data, loading, error }] = useSmartlightExpertAdviceMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      question: // value for 'question'
 *      company: // value for 'company'
 *   },
 * });
 */
export function useSmartlightExpertAdviceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.SmartlightExpertAdviceMutation, Types.SmartlightExpertAdviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.SmartlightExpertAdviceMutation, Types.SmartlightExpertAdviceMutationVariables>(SmartlightExpertAdviceDocument, options);
      }
export type SmartlightExpertAdviceMutationHookResult = ReturnType<typeof useSmartlightExpertAdviceMutation>;
export type SmartlightExpertAdviceMutationResult = Apollo.MutationResult<Types.SmartlightExpertAdviceMutation>;
export type SmartlightExpertAdviceMutationOptions = Apollo.BaseMutationOptions<Types.SmartlightExpertAdviceMutation, Types.SmartlightExpertAdviceMutationVariables>;
export const EmailConfirmRequestDocument = gql`
    mutation EmailConfirmRequest {
  emailConfirmRequest {
    email
    errors {
      ...EmailConfirmRequestError
    }
  }
}
    ${EmailConfirmRequestErrorFragmentDoc}`;
export type EmailConfirmRequestMutationFn = Apollo.MutationFunction<Types.EmailConfirmRequestMutation, Types.EmailConfirmRequestMutationVariables>;

/**
 * __useEmailConfirmRequestMutation__
 *
 * To run a mutation, you first call `useEmailConfirmRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailConfirmRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailConfirmRequestMutation, { data, loading, error }] = useEmailConfirmRequestMutation({
 *   variables: {
 *   },
 * });
 */
export function useEmailConfirmRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.EmailConfirmRequestMutation, Types.EmailConfirmRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.EmailConfirmRequestMutation, Types.EmailConfirmRequestMutationVariables>(EmailConfirmRequestDocument, options);
      }
export type EmailConfirmRequestMutationHookResult = ReturnType<typeof useEmailConfirmRequestMutation>;
export type EmailConfirmRequestMutationResult = Apollo.MutationResult<Types.EmailConfirmRequestMutation>;
export type EmailConfirmRequestMutationOptions = Apollo.BaseMutationOptions<Types.EmailConfirmRequestMutation, Types.EmailConfirmRequestMutationVariables>;
export const CompanyAdminBaseDocument = gql`
    query CompanyAdminBase {
  companyAdmin(first: 10) {
    edges {
      node {
        ...CompanyAdminBase
      }
    }
    totalCount
  }
}
    ${CompanyAdminBaseFragmentDoc}`;

/**
 * __useCompanyAdminBaseQuery__
 *
 * To run a query within a React component, call `useCompanyAdminBaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyAdminBaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyAdminBaseQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyAdminBaseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CompanyAdminBaseQuery, Types.CompanyAdminBaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.CompanyAdminBaseQuery, Types.CompanyAdminBaseQueryVariables>(CompanyAdminBaseDocument, options);
      }
export function useCompanyAdminBaseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.CompanyAdminBaseQuery, Types.CompanyAdminBaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.CompanyAdminBaseQuery, Types.CompanyAdminBaseQueryVariables>(CompanyAdminBaseDocument, options);
        }
export function useCompanyAdminBaseSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<Types.CompanyAdminBaseQuery, Types.CompanyAdminBaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<Types.CompanyAdminBaseQuery, Types.CompanyAdminBaseQueryVariables>(CompanyAdminBaseDocument, options);
        }
export type CompanyAdminBaseQueryHookResult = ReturnType<typeof useCompanyAdminBaseQuery>;
export type CompanyAdminBaseLazyQueryHookResult = ReturnType<typeof useCompanyAdminBaseLazyQuery>;
export type CompanyAdminBaseSuspenseQueryHookResult = ReturnType<typeof useCompanyAdminBaseSuspenseQuery>;
export type CompanyAdminBaseQueryResult = Apollo.QueryResult<Types.CompanyAdminBaseQuery, Types.CompanyAdminBaseQueryVariables>;
export const ProfileDocument = gql`
    query Profile {
  profile {
    ...UserProfile
  }
}
    ${UserProfileFragmentDoc}`;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ProfileQuery, Types.ProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.ProfileQuery, Types.ProfileQueryVariables>(ProfileDocument, options);
      }
export function useProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ProfileQuery, Types.ProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.ProfileQuery, Types.ProfileQueryVariables>(ProfileDocument, options);
        }
export function useProfileSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<Types.ProfileQuery, Types.ProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<Types.ProfileQuery, Types.ProfileQueryVariables>(ProfileDocument, options);
        }
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileSuspenseQueryHookResult = ReturnType<typeof useProfileSuspenseQuery>;
export type ProfileQueryResult = Apollo.QueryResult<Types.ProfileQuery, Types.ProfileQueryVariables>;
export const AddressUpdateDocument = gql`
    mutation AddressUpdate($input: AddressUpdateInput!) {
  addressUpdate(input: $input) {
    address
    errors {
      ...AddressUpdateError
    }
  }
}
    ${AddressUpdateErrorFragmentDoc}`;
export type AddressUpdateMutationFn = Apollo.MutationFunction<Types.AddressUpdateMutation, Types.AddressUpdateMutationVariables>;

/**
 * __useAddressUpdateMutation__
 *
 * To run a mutation, you first call `useAddressUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddressUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addressUpdateMutation, { data, loading, error }] = useAddressUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddressUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.AddressUpdateMutation, Types.AddressUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.AddressUpdateMutation, Types.AddressUpdateMutationVariables>(AddressUpdateDocument, options);
      }
export type AddressUpdateMutationHookResult = ReturnType<typeof useAddressUpdateMutation>;
export type AddressUpdateMutationResult = Apollo.MutationResult<Types.AddressUpdateMutation>;
export type AddressUpdateMutationOptions = Apollo.BaseMutationOptions<Types.AddressUpdateMutation, Types.AddressUpdateMutationVariables>;
export const MessageCountDocument = gql`
    query MessageCount {
  messageCount {
    ...MessageCount
  }
}
    ${MessageCountFragmentDoc}`;

/**
 * __useMessageCountQuery__
 *
 * To run a query within a React component, call `useMessageCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessageCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.MessageCountQuery, Types.MessageCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MessageCountQuery, Types.MessageCountQueryVariables>(MessageCountDocument, options);
      }
export function useMessageCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MessageCountQuery, Types.MessageCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MessageCountQuery, Types.MessageCountQueryVariables>(MessageCountDocument, options);
        }
export function useMessageCountSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<Types.MessageCountQuery, Types.MessageCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<Types.MessageCountQuery, Types.MessageCountQueryVariables>(MessageCountDocument, options);
        }
export type MessageCountQueryHookResult = ReturnType<typeof useMessageCountQuery>;
export type MessageCountLazyQueryHookResult = ReturnType<typeof useMessageCountLazyQuery>;
export type MessageCountSuspenseQueryHookResult = ReturnType<typeof useMessageCountSuspenseQuery>;
export type MessageCountQueryResult = Apollo.QueryResult<Types.MessageCountQuery, Types.MessageCountQueryVariables>;
export const SupportRequestDocument = gql`
    mutation SupportRequest($input: SupportRequestInput!) {
  supportRequest(input: $input) {
    errors {
      ...SupportRequestError
    }
  }
}
    ${SupportRequestErrorFragmentDoc}`;
export type SupportRequestMutationFn = Apollo.MutationFunction<Types.SupportRequestMutation, Types.SupportRequestMutationVariables>;

/**
 * __useSupportRequestMutation__
 *
 * To run a mutation, you first call `useSupportRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupportRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supportRequestMutation, { data, loading, error }] = useSupportRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSupportRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.SupportRequestMutation, Types.SupportRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.SupportRequestMutation, Types.SupportRequestMutationVariables>(SupportRequestDocument, options);
      }
export type SupportRequestMutationHookResult = ReturnType<typeof useSupportRequestMutation>;
export type SupportRequestMutationResult = Apollo.MutationResult<Types.SupportRequestMutation>;
export type SupportRequestMutationOptions = Apollo.BaseMutationOptions<Types.SupportRequestMutation, Types.SupportRequestMutationVariables>;
export const ControlpanelTimelineDocument = gql`
    query ControlpanelTimeline($before: String, $after: String) {
  timeline(first: 10, before: $before, after: $after) {
    edges {
      node {
        ...Timeline
      }
    }
    pageInfo {
      ...PageInfo
    }
    totalCount
  }
}
    ${TimelineFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useControlpanelTimelineQuery__
 *
 * To run a query within a React component, call `useControlpanelTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useControlpanelTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useControlpanelTimelineQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useControlpanelTimelineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ControlpanelTimelineQuery, Types.ControlpanelTimelineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.ControlpanelTimelineQuery, Types.ControlpanelTimelineQueryVariables>(ControlpanelTimelineDocument, options);
      }
export function useControlpanelTimelineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ControlpanelTimelineQuery, Types.ControlpanelTimelineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.ControlpanelTimelineQuery, Types.ControlpanelTimelineQueryVariables>(ControlpanelTimelineDocument, options);
        }
export function useControlpanelTimelineSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<Types.ControlpanelTimelineQuery, Types.ControlpanelTimelineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<Types.ControlpanelTimelineQuery, Types.ControlpanelTimelineQueryVariables>(ControlpanelTimelineDocument, options);
        }
export type ControlpanelTimelineQueryHookResult = ReturnType<typeof useControlpanelTimelineQuery>;
export type ControlpanelTimelineLazyQueryHookResult = ReturnType<typeof useControlpanelTimelineLazyQuery>;
export type ControlpanelTimelineSuspenseQueryHookResult = ReturnType<typeof useControlpanelTimelineSuspenseQuery>;
export type ControlpanelTimelineQueryResult = Apollo.QueryResult<Types.ControlpanelTimelineQuery, Types.ControlpanelTimelineQueryVariables>;
export const MarketOfferClickListDocument = gql`
    query MarketOfferClickList($first: Int, $after: String, $last: Int, $before: String, $sort: OfferClickSortingInput) {
  offerClicks(
    before: $before
    after: $after
    first: $first
    last: $last
    sortBy: $sort
  ) {
    edges {
      node {
        __typename
        ...MarketOfferClick
      }
    }
    pageInfo {
      ...PageInfo
    }
    totalCount
  }
}
    ${MarketOfferClickFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useMarketOfferClickListQuery__
 *
 * To run a query within a React component, call `useMarketOfferClickListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketOfferClickListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketOfferClickListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useMarketOfferClickListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.MarketOfferClickListQuery, Types.MarketOfferClickListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MarketOfferClickListQuery, Types.MarketOfferClickListQueryVariables>(MarketOfferClickListDocument, options);
      }
export function useMarketOfferClickListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MarketOfferClickListQuery, Types.MarketOfferClickListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MarketOfferClickListQuery, Types.MarketOfferClickListQueryVariables>(MarketOfferClickListDocument, options);
        }
export function useMarketOfferClickListSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<Types.MarketOfferClickListQuery, Types.MarketOfferClickListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<Types.MarketOfferClickListQuery, Types.MarketOfferClickListQueryVariables>(MarketOfferClickListDocument, options);
        }
export type MarketOfferClickListQueryHookResult = ReturnType<typeof useMarketOfferClickListQuery>;
export type MarketOfferClickListLazyQueryHookResult = ReturnType<typeof useMarketOfferClickListLazyQuery>;
export type MarketOfferClickListSuspenseQueryHookResult = ReturnType<typeof useMarketOfferClickListSuspenseQuery>;
export type MarketOfferClickListQueryResult = Apollo.QueryResult<Types.MarketOfferClickListQuery, Types.MarketOfferClickListQueryVariables>;
export const TermsUpdateDocument = gql`
    mutation TermsUpdate($input: TermsUpdateInput!) {
  termsUpdate(input: $input) {
    terms {
      ...MarketTerms
    }
    errors {
      ...TermsError
    }
  }
}
    ${MarketTermsFragmentDoc}
${TermsErrorFragmentDoc}`;
export type TermsUpdateMutationFn = Apollo.MutationFunction<Types.TermsUpdateMutation, Types.TermsUpdateMutationVariables>;

/**
 * __useTermsUpdateMutation__
 *
 * To run a mutation, you first call `useTermsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTermsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [termsUpdateMutation, { data, loading, error }] = useTermsUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTermsUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.TermsUpdateMutation, Types.TermsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.TermsUpdateMutation, Types.TermsUpdateMutationVariables>(TermsUpdateDocument, options);
      }
export type TermsUpdateMutationHookResult = ReturnType<typeof useTermsUpdateMutation>;
export type TermsUpdateMutationResult = Apollo.MutationResult<Types.TermsUpdateMutation>;
export type TermsUpdateMutationOptions = Apollo.BaseMutationOptions<Types.TermsUpdateMutation, Types.TermsUpdateMutationVariables>;
export const MarketTermsDocument = gql`
    query MarketTerms {
  terms {
    ...MarketTerms
  }
}
    ${MarketTermsFragmentDoc}`;

/**
 * __useMarketTermsQuery__
 *
 * To run a query within a React component, call `useMarketTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketTermsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketTermsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.MarketTermsQuery, Types.MarketTermsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MarketTermsQuery, Types.MarketTermsQueryVariables>(MarketTermsDocument, options);
      }
export function useMarketTermsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MarketTermsQuery, Types.MarketTermsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MarketTermsQuery, Types.MarketTermsQueryVariables>(MarketTermsDocument, options);
        }
export function useMarketTermsSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<Types.MarketTermsQuery, Types.MarketTermsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<Types.MarketTermsQuery, Types.MarketTermsQueryVariables>(MarketTermsDocument, options);
        }
export type MarketTermsQueryHookResult = ReturnType<typeof useMarketTermsQuery>;
export type MarketTermsLazyQueryHookResult = ReturnType<typeof useMarketTermsLazyQuery>;
export type MarketTermsSuspenseQueryHookResult = ReturnType<typeof useMarketTermsSuspenseQuery>;
export type MarketTermsQueryResult = Apollo.QueryResult<Types.MarketTermsQuery, Types.MarketTermsQueryVariables>;