import {
    ApolloError,
    ApolloQueryResult,
    LazyQueryHookOptions as BaseLazyQueryHookOptions,
    QueryHookOptions as BaseQueryHookOptions,
    DocumentNode,
    OperationVariables,
    QueryResult,
    useQuery as useBaseQuery
} from '@apollo/client';
import { TypedDocumentNode } from '@apollo/client/core';
import { NoInfer } from '@apollo/client/react/types/types';
import { useEffect } from 'react';
import useAppState from '../../controlpanel/hooks/useAppState';
import { RequireAtLeastOne } from '../../controlpanel/misc';

export { useLazyQuery } from '@apollo/client';

export interface LoadMore<TData, TVariables extends OperationVariables> {
    loadMore: (
        mergeFunc: (prev: TData, next: TData) => TData,
        extraVariables: Partial<TVariables>
    ) => Promise<ApolloQueryResult<TData>>;
}

export type LazyQueryHookOptions<
    TData = any,
    TVariables extends OperationVariables = OperationVariables
> = BaseLazyQueryHookOptions<TData, TVariables>;

export type UseQueryResult<TData, TVariables extends OperationVariables> = QueryResult<TData, TVariables> &
    LoadMore<TData, TVariables>;
export type QueryHookOptions<TData, TVariables extends OperationVariables> = Partial<
    Omit<BaseQueryHookOptions<TData, TVariables>, 'variables'> & {
    displayLoader: boolean;
    handleError?: (error: ApolloError) => void | undefined;
    variables?: TVariables;
}
>;

type UseQueryHook<TData, TVariables extends OperationVariables> = (
    opts?: QueryHookOptions<TData, TVariables>
) => UseQueryResult<TData, TVariables>;

export function useQuery<TData, TVariables extends OperationVariables>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    {
        displayLoader,
        skip,
        variables,
        fetchPolicy,
        handleError,
        ...opts
    }: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>> = {}
): UseQueryResult<TData, TVariables> {
    const [, dispatchAppState] = useAppState();

    const queryData = useBaseQuery(query, {
        ...opts,
        context: {
            useBatching: true
        },
        errorPolicy: 'all',
        fetchPolicy: fetchPolicy ?? 'cache-and-network',
        onError: error => {
            if (handleError) {
                handleError(error);
            }
        },
        skip,
        variables: variables
    });

    useEffect(() => {
        if (displayLoader) {
            dispatchAppState({
                payload: {
                    value: queryData.loading
                },
                type: 'displayLoader'
            });
        }
    }, [queryData.loading]);

    const loadMore = (
        mergeFunc: (previousResults: TData, fetchMoreResult: TData) => TData,
        extraVariables: RequireAtLeastOne<TVariables>
    ) =>
        queryData.fetchMore({
            query,
            updateQuery: (previousResults, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return previousResults;
                }
                return mergeFunc(previousResults, fetchMoreResult);
            },
            variables: { ...variables, ...extraVariables }
        });

    return {
        ...queryData,
        loadMore
    };
}

function makeQuery<TData, TVariables extends OperationVariables>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>
): UseQueryHook<TData, TVariables> {
    return (opts: QueryHookOptions<TData, TVariables>) =>
        useQuery<TData, TVariables>(query, opts);
}

export default makeQuery;
