/* eslint-disable */

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Node": [
      "Article",
      "ArticleRubrica",
      "Comment",
      "Company",
      "CompanyAdmin",
      "ContentType",
      "Document",
      "DocumentCategory",
      "DocumentPage",
      "Event",
      "EventCategory",
      "Favorite",
      "MagazineSubscription",
      "Manual",
      "News",
      "NewsRubrica",
      "Offer",
      "OfferCategory",
      "OfferClick",
      "Order",
      "OrderItem",
      "OrderMessage",
      "Person",
      "PersonCategory",
      "Post",
      "PostRubrica",
      "PostType",
      "Profile",
      "SmartlightExpertAdvice",
      "Subscription",
      "Terms",
      "Timeline",
      "User",
      "Video",
      "VideoRubrica",
      "XMLPrice"
    ]
  }
};
      export default result;
    